'use client'

export default function NotFound() {
  return (
    <div className="not_error_wrap">
      <div className={"not_error_box"}>
        <h2>404 해당페이지를 찾을수 없습니다.</h2>
        <button onClick={() => window.location.reload()}>새로고침</button>
        <button onClick={() => window.location.href = "/"}>메인으로 돌아가기</button>
      </div>
    </div>
  );
}